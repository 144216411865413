import appEnvironment from './environment';
import { isNotificationSupported } from '../utils/useFirebase';
import { ObjectType } from '../types/types';

const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
const BRANCH = process.env.REACT_APP_BRANCH;

const TOKEN_KEY: string = process.env.REACT_APP_TOKEN_KEY ? process.env.REACT_APP_TOKEN_KEY : "twigsee.token";
const GITLAB_KEY: string = process.env.REACT_APP_GITLAB_ACCESS_TOKEN ? process.env.REACT_APP_GITLAB_ACCESS_TOKEN : "";
const FIREBASE_KEY: string = process.env.REACT_APP_FIREBASE_KEY_PAIR ? process.env.REACT_APP_FIREBASE_KEY_PAIR : "";
const DATA_KEY: string = "twigsee.data";

const APP_NAME: string = "Twigsee";
const APP_ENVIRONMENT: string = ENVIRONMENT ? ((ENVIRONMENT === "localhost" || ENVIRONMENT === "review" || ENVIRONMENT === "stage" || ENVIRONMENT === "production") ? ENVIRONMENT : "production") : "production";
const API_SERVERS: ObjectType = appEnvironment[APP_ENVIRONMENT]["apiServers"];
const APP_LOGO: string = appEnvironment[APP_ENVIRONMENT]["logo"];
const APP_LOGO_CIRCLE: string = appEnvironment[APP_ENVIRONMENT]["logoCircle"];
const APP_LOGO_SMALL: string = appEnvironment[APP_ENVIRONMENT]["logoSmall"];
const APP_DELIMITER: string = "|";
const APP_SPLITTER: string = "—";
const APP_PLATFORM: string = "webapp";
const APP_VERSION: string = "2.3.2";
const APP_LABEL: string = "a";
const APP_EMBED_VERSION: string = "0.1";

const APP_USER_ROLES: ObjectType = {
  2: "teacher",
  3: "parent",
  4: "director"
};

const APP_USER_HOMEPAGE: ObjectType = {
  "teacher": "/timeline",
  "parent": "/timeline",
  "director": "/timeline" //dashboard
};

const APP_INSTANT_ACCESS_PAGES: string[] = [
  "403",
  "404",
  "500",
  "auth/logout",
  "auth/sso",
  "auth/switch",
  "auth/reset-password",
  "auth/reset-password-request",
];

const APP_INSTANT_ACCESS_HASHES: string[] = [
  "logout",
];

const APP_CHANGELOG_VERSION: string = `${APP_VERSION}(${APP_LABEL})`;
const APP_CHANGELOG_ROLES: string[] = ["parent", "teacher", "director"];

const APP_TIMEZONE: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
const APP_ONLY_LOCAL_LANGUAGES: boolean = process.env.REACT_APP_ONLY_LOCAL_LANGUAGES === "true" ? true : false;
const APP_SHOW_METRICS_IN_CONSOLE: boolean = process.env.REACT_APP_SHOW_METRICS_IN_CONSOLE === "true" ? false : false;
const APP_NOTIFICATIONS = {
  fcmToken: "",
  permission: isNotificationSupported ? Notification.permission : false,
  position: 'top-right',
  timeout: 3500,
};

const WEBSITE: string = "https://www.twigsee.com/";
const SUPPORT_EMAIL: string = "helpdesk@twigsee.com"; //support@twigsee.com

const UPLOAD_EXT_FILES: string[] = ["pdf", "doc", "docx", "xls", "xlsx", "ppt", "pptx"];
const UPLOAD_EXT_PHOTOS: string[] = ["jpg", "jpeg", "png", "gif", "apng", "webp", /*"heif", "heic",*/ "avif", "bmp", "tif"];
const UPLOAD_EXT_VIDEOS: string[] = ["mp4", "ogg", "webm"];
const UPLOAD_FORMATS_FILES: string[] = ["application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-powerpoint", "application/vnd.openxmlformats-officedocument.presentationml.presentation"];
const UPLOAD_FORMATS_PHOTOS: string[] = ["image/jpg", "image/jpeg", "image/png", "image/gif","image/vnd.mozilla.apng","image/apng", "image/webp",/*"image/heic","image/heif",*/"image/avif","image/bmp","image/tif"];
const UPLOAD_FORMATS_VIDEOS: string[] = ["video/mp4", "video/ogg", "video/webm", "audio/ogg"];
const UPLOAD_PROFILE_PHOTO_MAX_SIZE: number = 2097152;
const PREVIEW_EXT: string[] = ["pdf", "mp4", "webm", "ogg", "mov"];

const HIDDEN_ACTIVITY_TYPES: number[] = [13, 14, 15];
const MAX_ITEMS_IN_CART: number = 99999;
const TIMELINE_POSTS_LIMIT: number = 10;
const FINANCE_PAYMENTS_LIMIT: number = 10;
const GALLERY_DOWNLOADS_LIMIT: number = 50;

const IMAGE_DEFAULT_FORMAT: string = "JPEG";
const IMAGE_MAX_WIDTH: number = 2000;
const IMAGE_MAX_HEIGHT: number = 2000;
const IMAGE_QUALITY: number = 70;
const IMAGE_PLACEHOLDER: string = "/resources/images/image_placeholder.jpg";

const JSONLD_DATA = "hydra:member";
const JSONLD_COUNT = "hydra:totalItems";

const config: ObjectType = {
  ENVIRONMENT,
  BRANCH,
  TOKEN_KEY,
  GITLAB_KEY,
  FIREBASE_KEY,
  DATA_KEY,
  API_SERVERS,
  APP_ENVIRONMENT,
  APP_NAME,
  APP_LOGO,
  APP_LOGO_CIRCLE,
  APP_LOGO_SMALL,
  APP_DELIMITER,
  APP_SPLITTER,
  APP_PLATFORM,
  APP_VERSION,
  APP_EMBED_VERSION,
  APP_USER_ROLES,
  APP_USER_HOMEPAGE,
  APP_INSTANT_ACCESS_PAGES,
  APP_INSTANT_ACCESS_HASHES,
  APP_CHANGELOG_VERSION,
  APP_CHANGELOG_ROLES,
  APP_TIMEZONE,
  APP_ONLY_LOCAL_LANGUAGES,
  APP_SHOW_METRICS_IN_CONSOLE,
  APP_NOTIFICATIONS,
  WEBSITE,
  SUPPORT_EMAIL,
  UPLOAD_EXT_FILES,
  UPLOAD_EXT_PHOTOS,
  UPLOAD_EXT_VIDEOS,
  UPLOAD_FORMATS_FILES,
  UPLOAD_FORMATS_PHOTOS,
  UPLOAD_FORMATS_VIDEOS,
  UPLOAD_PROFILE_PHOTO_MAX_SIZE,
  PREVIEW_EXT,
  HIDDEN_ACTIVITY_TYPES,
  MAX_ITEMS_IN_CART,
  TIMELINE_POSTS_LIMIT,
  FINANCE_PAYMENTS_LIMIT,
  GALLERY_DOWNLOADS_LIMIT,
  IMAGE_DEFAULT_FORMAT,
  IMAGE_MAX_WIDTH,
  IMAGE_MAX_HEIGHT,
  IMAGE_QUALITY,
  IMAGE_PLACEHOLDER,
  JSONLD_DATA,
  JSONLD_COUNT,
};

export default config;